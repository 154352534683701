import React, { Component } from 'react';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import axios from 'axios';
import qs from 'query-string';
import { API_BASE_URL } from '../../../constants';

class CheckoutForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			users: '',
			coupon: ''
		}
		this.submit = this.submit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNumber = this.handleNumber.bind(this);
	}

	async submit() {
		this.props.setLoader(true);
		const token = qs.parse(this.props.location.search).token;

        const cardElement = this.props.elements.getElement(CardElement);

        const results = await this.props.stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });
        console.log(results);
		if (results.paymentMethod) {
			let data = {
				token: token,
				quantity: this.state.users,
				stripeToken: results.paymentMethod.id,
				coupon: this.state.coupon
			};
			axios.post(`${API_BASE_URL}/v1/auth/confirmorganisation`,
				data,
				{ headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
				}
			}).then(res => {
				this.props.setLoader(false);
				swal({
					title: "Success!",
					text: "Confirmation Success",
					type: "success",
				}).then(() => {
					window.location.href = '/login';
				});
				setTimeout(() => {
					window.location.href = '/login';
				}, 5000);
			}).catch(err => {
				this.props.setLoader(false);
				swal(
					'Error',
					err.response.data.message,
					'error'
				);
			});
		} else if (results.error) {
			this.props.setLoader(false);
			swal(
				'Error',
				results.error.message,
				'error'
			)
		}
	}

	handleChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	handleNumber() {
		if (this.state.users === '') return;
		this.setState({
			users: Math.ceil(this.state.users / 10) * 10
		})
	}

	render() {
		const cardStyle = {
			base: {
				border: '1px solid black'
			}
		};
		return (
			<div className="checkout">
				<label className="label">Confirm Payment Details</label>
				<div className="form-group">
					<input type="text" placeholder="Name" name="name" onChange={this.handleChange} className="form-control" />
				</div>
				<div className="form-group">
					<input type="number" placeholder="Number Of Users" value={this.state.users} name="users" onChange={this.handleChange} onBlur={this.handleNumber} className="form-control" />
					<label style={{color: 'red', fontSize: '12px', fontStyle: 'italic'}}>Note: users are billed in blocks of 10 users</label>
				</div>
				<div className="form-group">
					<input type="text" placeholder="Coupon Code (Optional)" name="coupon" onChange={this.handleChange} className="form-control" />
				</div>
				<div className="form-group no-animation">
					<CardElement hidePostalCode={true} style={cardStyle} />
				</div>
				<button className="btn btn-orange btn-full" onClick={this.submit}>Submit</button>
			</div>
		);
	}
}


const InjectedCheckoutForm = (props) => (
    <ElementsConsumer>
        {({ stripe, elements}) => (
            <CheckoutForm stripe={stripe} elements={elements} location={props.location} setLoader={props.setLoader} />
        )}
    </ElementsConsumer>
);

export default InjectedCheckoutForm;

// export default <ElementsConsumer>
//     {({ stripe, elements }) => (
//         <CheckoutForm stripe={stripe} elements={elements} />
//     )}
// </ElementsConsumer>
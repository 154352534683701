import React from 'react';
import './game-detail.scss';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from '../../../../loader/loader';
import moment from 'moment';
import withAuth from '../../../../AuthHOC/withAuth';
import classNames from 'classnames';
import Modal from 'react-responsive-modal';
import ModalTravel from './modal-travel/modal-travel';
import ReportField from '../../../../report-field/report-field';
import {getUserId, API_BASE_URL, getHeaders} from '../../../../../constants';
import swal from 'sweetalert2/dist/sweetalert2.js';
import Axios from 'axios';
import * as AWS from 'aws-sdk';
//import { IdealBankElement } from 'react-stripe-elements';

class GameDetailPage extends React.Component {

	s3Client;

	constructor(props) {
		super(props);
		AWS.config.region = 'ap-southeast-2'; // Region
		AWS.config.update({
			accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
			secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            region: process.env.REACT_APP_AWS_DEFAULT_REGION,
            maxRetries: 2,
            httpOptions: {
                timeout:  1800000000,
                // connectTimeout: 5000
            }
		});
		this.s3Client = new AWS.S3();
		this.state = {
			game: null,
			home: null,
			away: null,
			league: null,
			organisation: null,
			pattern: null,
			venue: null,
			fields: [],
			referees: {},
			isModalOpened: false,
			alreadyHasReport: false,
			isTravelOpened: false,
			alreadyHasTravelClaim: false,
			hasReport: false,
			isEdit: false,
			isError: false,
			isPublished: false,
			auth_user: JSON.parse(localStorage.getItem('user')),
			isDownloadLoading: false,
			hasVideo: false,
		}
		this.modalBody = React.createRef();
		this.statusColor = this.statusColor.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.scrollToTop = this.scrollToTop.bind(this);
		this.openModalTravel = this.openModalTravel.bind(this);
		this.closeModalTravel = this.closeModalTravel.bind(this);
		this.submitTravelClaim = this.submitTravelClaim.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handlePublish = this.handlePublish.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleConfirm = this.handleConfirm.bind(this);
		this.handleDecline = this.handleDecline.bind(this);
	}

	statusColor(status) {
		switch (status) {
			case "Pending": return 'yellow'
			case "Confirmed": return 'green'
			case "Declined": return 'red'
			case "Removed": return 'red'
			default: return ''
		}
	}

	openModal = () => {
		this.setState({ isModalOpened: true });
	}

	closeModal = () => {
		this.setState({ isModalOpened: false, isEdit: false });
	}

	openModalTravel = () => {
		this.setState({ isTravelOpened: true });
	}

	closeModalTravel = () => {
		this.setState({ isTravelOpened: false });
	}

	handleSave(data) {
		this.props.savePerformanceReport(this.props.match.params.id, data);
	}

	handleSubmit(data) {
		this.props.submitPerformanceReport(this.props.match.params.id, data);
	}

	handlePublish(data) {
		this.props.publishPerformanceReport(this.props.match.params.id, data);
	}

	submitTravelClaim(data) {
		this.props.submitTravelClaim(this.props.match.params.id, data);
	}

	scrollToTop() {
		this.modalBody.current.scrollTop = 0;
	}

	handleEdit() {
		this.setState({
			isEdit: true
		});
	}

	handleConfirm() {
		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, confirm this game!'
		}).then((result) => {
			if (result.value) {
				const id = this.props.match.params.id;
				this.props.confirmGameToOfficial(id, this.props.fetchGame);
			}
		})
	}

	handleDecline() {
		swal({
			title: 'Are you sure?',
			text: "Reason for declining this game?",
			type: 'warning',
			input: 'text',
			inputValidator: (value) => {
				return new Promise((resolve) => {
					if (value.length < 10) {
						resolve('Your reason is  not detailed enough, please re-enter your reason');
					} else {
						resolve();
					}
				})
			},
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, decline it!'
		}).then((result) => {
			if (result.value) {
				const id = this.props.match.params.id;
				this.props.declineGameToOfficial(id, result.value, this.props.fetchGame);
			}
		})
	}

	isPending = (data) => {
		const status = this.state.referees;
		let arr = [];
		Object.keys(data).forEach(obj => {
			if (typeof status[obj] === 'object') {
				let newObj = {
					official_id: status[obj].official.id,
					status: status[obj].status
				};
				arr.push(newObj);
			}
		});
		const results = arr.filter(official => {
			return official.official_id === getUserId() && official.status === 'Pending';
		});
		return results.length > 0;
	}

	componentWillMount() {
		this.props.resetData();
		this.props.fetchGame(this.props.match.params.id);
		this.props.getPerformanceReport(this.props.match.params.id);
		this.props.getTravelClaimPerformed(this.props.match.params.id);
	}

	componentWillReceiveProps(nextProps) {
		if (Object.keys(nextProps.game).length > 0) {
			//console.log(nextProps.game.game);
			this.setState({
				game: nextProps.game.game,
				home: nextProps.game.home_team,
				away: nextProps.game.away_team,
				league: nextProps.game.league,
				organisation: nextProps.game.organisation,
				pattern: nextProps.game.pattern,
				venue: nextProps.game.venue,
				fields: nextProps.game.fields,
				referees: nextProps.game.referees
			});
			this.getVideo(nextProps.game.game.organisation_id, this.props.match.params.id);
		}

		if (nextProps.travel_claim == null) {
			this.setState({
				alreadyHasTravelClaim: false
			});
		} else if (nextProps.travel_claim != null) {
			this.setState({
				alreadyHasTravelClaim: true
			});
		}

		if (nextProps.perf_report_data == null) {
			this.setState({
				hasReport: false
			});
		} else if (nextProps.perf_report_data != null) {
			if (nextProps.perf_report_data.status === 'Submitted' || nextProps.perf_report_data.status === "Published" || nextProps.perf_report_data.status === 'Saved') {
				this.setState({
					hasReport: true
				});
			} else {
				this.setState({
					hasReport: false
				});
			}

			if (nextProps.perf_report_data.error) {
				this.setState({
					isError: true
				});
			} else {
				this.setState({
					isError: false
				});
			}

			if (nextProps.perf_report_data.status === 'Published') {
				this.setState({
					isPublished: true
				})
			} else {
				this.setState({
					isPublished: false
				})
			}
		}
	}

	getVideo(orgid, id) {
		Axios.get(
			`${API_BASE_URL}/v1/auth/organisation/${orgid}/games/${id}/download`,
			{
				headers: getHeaders(),
			}
		).then(res => {
			this.setState({
				hasVideo: true
			})
		}).catch(err => {
			this.setState({
				hasVideo: false
			})
		})
	}

	downloadGame = e => {
		console.log(this.props.match.params.id);
		this.setState({
			isDownloadLoading: true
		})
		const id = this.props.match.params.id;
		Axios.get(
			`${API_BASE_URL}/v1/auth/organisation/${this.state.game.organisation_id}/games/${id}/download`,
			{
				headers: getHeaders(),
			}
		).then(res => {
			console.log(res)
			this.setState({
				isDownloadLoading: false
			})
			const path =  res.data.path;
            var params = {
                Bucket: process.env.REACT_APP_AWS_BUCKET, 
                Key: path,
                Expires: 10800, 
                // Range: "bytes=0-10000000"
            };
            this.s3Client.getSignedUrl('getObject', params, function (err, url) {
				console.log(err);
                if(!err) {
				console.log('The URL is', url);
                const a = document.createElement('A');
                a.href = url;
                a.download = url.substr(url.lastIndexOf('/') + 1);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
				}
              });
		}).catch(err => {
			console.error(err);
			this.setState({
				isDownloadLoading: false
			})
			swal({
				title: 'Error',
				text: err.response.data.message,
				type: 'error',
			})
		})
	}

	render() {
		const gender = {
			'1': 'Men',
			'2': 'Women',
			'3': 'Boys',
			'4': 'Girls',
			'5': 'Mixed'
		}
		let latitude = null;
		let longitude = null;

		if (this.state.venue && this.state.venue.venue_coords != null) {
			let coords = this.state.venue.venue_coords.split(', ');
			latitude = coords[0];
			longitude = coords[1];
		}
		const { isModalOpened, isTravelOpened } = this.state;
		let modalLoading = this.props.perf_report_loading;
		let travelLoading = this.props.travel_claim_loading;

		const isPending = this.isPending(this.state.referees);
		return (
			<div className="game-detail">
				{this.props.loading && <Loading />}
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">{this.state.home && `${this.state.home.team_name} vs`} {this.state.away && this.state.away.team_name}</h1>
						<nav>
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current"><Link to="/games">Games</Link></li>
								<li className="breadcrumb-item current">{this.state.home && `${this.state.home.team_name} vs`} {this.state.away && this.state.away.team_name}</li>
							</ol>
						</nav>
					</div>
					<div className="actions">
						{isPending && (
							<>
								<button type="button" className="btn btn-outline-success mr-2" onClick={this.handleConfirm}>Confirm</button>
								<button type="button" className="btn btn-outline-danger ml-0 mr-2" onClick={this.handleDecline}>Decline</button>
							</>
						)}
						<Link to="/mygames"><button className="btn btn-white mr_15">Back to all Games</button></Link>
					</div>
				</div>
				<div className="box">
					<div className="games mb_30">
						<div className="row">
							<div className="col-xl-6 col-lg-6 col-md-12 col-12 mb-sm-30 mb-md-30">
								<table className="table-data">
									<thead>
										<tr>
											<th width="30%">League</th>
											<td width="70%">{this.state.league && this.state.league.league_name}</td>
										</tr>
										<tr>
											<th>Date / Time</th>
											<td>{this.state.game && `${moment(this.state.game.game_date).format("MMM DD, YYYY")} /`} {this.state.game && moment(this.state.game.game_time, "HH:mm").format("LT")}</td>
										</tr>
										<tr>
											<th>Venue</th>
											<td>{this.state.venue && this.state.venue.venue_name}</td>
										</tr>
										<tr>
											<th>Gender</th>
											<td>{this.state.game && gender[this.state.game.gender]}</td>
										</tr>
										<tr>
											<th>Pattern</th>
											<td>{this.state.pattern && this.state.pattern.pattern_name}</td>
										</tr>
									</thead>
								</table>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-12 col-12">
								<div className="map">
									{/* <iframe width="100%" height="450" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=40.7127837,-74.0059413&amp;key=YOUR_API_KEY"></iframe> */}
									<iframe title="map" src={`//maps.google.com/maps?q=${latitude}, ${longitude}&z=15&output=embed`} width="360" height="270" frameBorder="0" style={{ border: 0 }}></iframe>
									{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4424751.652531875!2d130.68192050976077!3d-25.25529845479727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2b2bfd076787c5df%3A0x538267a1955b1352!2sAustralia!5e0!3m2!1sen!2sin!4v1543322921710" title="Venue Location"></iframe> */}
								</div>
							</div>
						</div>
					</div>
					<div className="asign-game mb_30">
						<h2>Assigned Officials</h2>
						<div className="wrap">
							<div className="row align-items-end">
								{this.state.fields.map((field, index) => (
									<div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-12" key={index}>
										<div className="list-items">
											<div className="row no-gutters justify-content-between">
												<div className="post col-lg-4 col-md-12 col-12 mb-2 mb-lg-0 d-flex align-items-center">{field.field_name}</div>
												<div className="name-status col-lg-8 col-md-12 col-12 d-flex align-items-center">
													<div className="row no-gutters">
														<div className={classNames('status', 'align-self-center', this.statusColor(Object.keys(this.state.referees).length > 0 ? this.state.referees[field.id].status : ''))}></div>
														<div className="name align-self-center">{Object.keys(this.state.referees).length > 0 && typeof this.state.referees[field.id] === "object" ? this.state.referees[field.id].official.name : this.state.referees[field.id]}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className="performance-report travel-claim">
						{this.state.isError
							? <p style={{ color: 'red' }} className="mb-2">{this.props.perf_report_data.message}</p>
							: <button onClick={this.openModal} className="btn btn-orange mr-4 mb-3">{this.state.hasReport && 'View'} Performance Report</button>
						}

						<button onClick={this.openModalTravel} className="btn btn-orange mb-3">{this.state.alreadyHasTravelClaim ? 'View Travel Claim' : 'Travel Claim'}</button>
						<Modal
							open={isModalOpened}
							onClose={this.closeModal}
							showCloseIcon={false}
							center
							classNames={{
								modal: "modal fade show"
							}}
						>
							<div className="modal-dialog modal-lg modal-dialog-centered">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title">Performance Report</h5>
										<button type="button" className="close" onClick={this.closeModal}>
											<span>&times;</span>
										</button>
									</div>
									<div className="modal-body" ref={this.modalBody} style={{ maxHeight: '65vh', overflowY: 'auto' }}>
										{modalLoading && <Loading />}
										<ReportField
											hasReport={this.state.hasReport}
											isEdit={this.state.isEdit}
											isPublished={this.state.isPublished}
											data={this.props.perf_report_data}
											handleEdit={this.handleEdit}
											handleSave={this.handleSave}
											handleSubmit={this.handleSubmit}
											handlePublish={this.handlePublish}
										/>
									</div>
								</div>
							</div>
						</Modal>

						<Modal
							open={isTravelOpened}
							onClose={this.closeModalTravel}
							showCloseIcon={false}
							center
							classNames={{
								modal: "modal fade show"
							}}
						>
							<div className="modal-dialog modal-lg modal-dialog-centered">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title">Travel Claim</h5>
										<button type="button" className="close" onClick={this.closeModalTravel}>
											<span>&times;</span>
										</button>
									</div>
									<div className="modal-body" style={{ position: 'relative' }}>
										{travelLoading && <Loading />}
										<ModalTravel
											alreadyHasTravelClaim={this.state.alreadyHasTravelClaim}
											travel_claim={this.props.travel_claim}
											onSubmit={this.submitTravelClaim}
										/>
									</div>
								</div>
							</div>
						</Modal>
						{this.state.hasVideo && Object.keys(this.state.referees).findIndex(key => this.state.referees[key].official && this.state.referees[key].official.id === this.state.auth_user.user.id) > -1 && <button onClick={this.downloadGame} disabled={this.state.isDownloadLoading} className="btn btn-orange mb-3 ml-2">
							{ this.state.isDownloadLoading ? (
								<React.Fragment><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								Loading...</React.Fragment>
							) : 'Download Game Video'}	
						</button>}
						{this.state.game && this.state.game.organisation_id && this.state.game.organisation_id === 1 && <button onClick={function() {
							window.open('https://submit.jotform.com/221451320986050', '_blank').focus();
						}} className="btn btn-orange mb-3 ml-2">
							Submit Report
						</button>}
					</div>
				</div>
			</div>
		);
	}
}

GameDetailPage.propTypes = {
	fetchGame: PropTypes.func.isRequired,
	getAssignReferee: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
}

export default withAuth(GameDetailPage, ['official']);
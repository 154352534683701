//export const API_BASE_URL = 'https://efficialtec-api.herokuapp.com';
// export const API_BASE_URL = 'https://bnswapi.efficialtec.com';
//export const API_BASE_URL = 'https://api.efficialtec.com';
export const API_BASE_URL = 'https://api.efficialtec.com';
//export const API_BASE_URL = 'http://127.0.0.1:8000';

// export const VIDEO_API_URL = 'https://api.video.efficialtec.com';
export const VIDEO_API_URL = 'http://video.devapi.efficialtec.com';

const currentUser = () => {
	return JSON.parse(localStorage.getItem('user'));
}

export const getCurrentUser = () => {
	return currentUser() == null ? null : currentUser();
};

export const getOrgId = () => {
	return currentUser() == null ? null : currentUser().current_org;
}

export const getUserId = () => {
	return currentUser() == null ? null : currentUser().user.id;
}

export const getToken = () => {
	return currentUser() == null ? null : currentUser().access_token;
}

export const getPermission = () => {
	return currentUser() == null ? null : currentUser().organisations.filter(org => org.org_id === getOrgId())[0]['permissions'];
}
export const getLockedStatus = () => {
	return currentUser() == null ? null : currentUser().organisations.filter(org => org.org_id === getOrgId())[0]['features'].lockdates;
}

export const getOrganisations = () => {
	return currentUser() == null ? null : currentUser().organisations;
}

export const getHeaders = () => {
	return {
		'Content-Type': 'application/json',
		'X-Requested-With': 'XMLHttpRequest',
		'Access-Control-Allow-Origin': 'http://localhost:3001',
		// 'Access-Control-Allow-Origin': 'https://cors-anywhere.herokuapp.com/http://localhost:3001',
		'Authorization': 'Bearer ' + getToken()
	}
}

export const getFormatVersion = () => {
	return 'v1.6.0';
}
import React, { Component } from 'react';
//import { CardElement, injectStripe } from 'react-stripe-elements';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import './form.scss';


class PaymentInfoForm extends Component {
	handleSubmit = async () => {
		try {
			const stripe = await this.props.stripe.createToken({ type: 'card' });
			if (stripe.error) {
				throw stripe.error.message
			} else if (stripe.token) {
				this.props.handleUpdateCard(stripe.token.id, this.cardRef.clear)
			}
		} catch (error) {
			swal(
				'Error',
				 error,
				'error'
			)
		}
	}

	render() {
		return (
			<div className="row">
				<div className="col-xl-6 col-lg-6 col-md-9 col-sm-12 mb-4 mb-md-0">
					{/* <CardElement
						hidePostalCode={true}
						onReady={element => this.cardRef = element}
					/> */}
				</div>
				<div className="col-xl-6 col-lg-6 col-md-3 col-sm-12">
					<button className="btn btn-orange" type="button" onClick={this.handleSubmit}>Submit</button>
				</div>
			</div>
		);
	}
}

export default PaymentInfoForm;
//export default injectStripe(PaymentInfoForm);